.about {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& .textWrapper {
		width: 400px;

		& .aboutHeader {
			font-size: 48px;
			font-family: helvetica;
			color: #fffdef;

			padding-left: 5vw;
		}

		& .aboutDescription {
			font-size: 14px;
			font-family: helvetica;
			color: #fffdef;

			line-height: 1.5;

			padding-left: 5vw;
			padding-right: 0;
		}
	}

	& .cloudWrapper {
		height: calc(100vh - 65px);

		margin: 0 calc((100vw - 400px - 500px) / 2);
	}

	@media only screen and (max-width: 950px) {
		flex-direction: column;

		& .textWrapper {
			width: 100vw;

			& .aboutDescription {
				padding-right: 5vw;
			}
		}

		& .cloudWrapper {
			height: 500px;
		}
	}
}