.projArticle {
	position: absolute;
	top: 65px;
	padding-top: 5px;

	overflow-x: hidden;
	overflow-y: scroll;

	width: 100%;
	height: calc(100vh - 65px);

	color: #FFFDEF;
	font-family: helvetica;
	top: 65px;

	& .title {
		font-size: 36px;
		text-align: center;
		margin-top: 10px;
	}

	& .subtitle {
		font-size: 18px;
		text-align: center;
	}

	.projectImage {
		width: 50vw;
		aspect-ratio: 16/9;
		border: 1px solid black;
		border-radius: 10px;
		margin: 0 25vw;
	}

	& .projBody {
		width: 60vw;
		margin: 0 20% 7.5%;

		& .sectionHeader {
			font-size: 20px;
			text-indent: 3vw;
		}

		& .text {
			font-size: 18px;
			line-height: 1.2;
		}

		& .bodyImage {
			width: 50vw;
			aspect-ratio: 16/9;
			border: 2px solid black;
			border-radius: 10px;
			margin: 10px 5vw;
		}

		& .comingSoon {
			text-align: center;
			font-size: 20px;
		}
	}
	
}