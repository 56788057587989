.home {
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;

	& .intro {
		font-size: 48px;
		font-family: helvetica;
		color: #fffdef;
		
		padding-left: 48px;

		height: calc(15vh - 65px);

		@media only screen and (max-width: 880px) {
			padding-top: 12px;
			padding-bottom: 24px;

			text-align: center;
		}
	}


	& .signAndButton {
		display: flex;
		flex-direction: row;
		align-items: center;

		height: calc(60vh - 65px);


		& .ledSign {
			transition: all 150ms linear;
			margin-left: 48px;

			width: 500px;

		}

		& .powerButton {

			visibility: hidden;
			width: calc(100vw - 500px);

			transition: all 150ms linear;

			display: inline-block;


			& .powerIcon {

				visibility: visible;

				width: 256px;
				height: 256px;
			}
		}

		@media only screen and (max-width: 880px) {
			flex-direction: column;

			height: auto;

			& .ledSign {
				margin: 0 calc(50vw - 250px);
			}

			& .powerButton {
				width: 256px;
				margin: 50px calc(50vw - 128px) 0;
			}
		}

		@media only screen and (max-width: 500px) {
			& .ledSign {
				width: 100vw;
				margin: 0;
			}	
		}

	}

	& .description {
		font-size: 48px;
		font-family: helvetica;
		color: #fffdef;

		padding-top: 48px;
		padding-left: 48px;

		height: calc(25vh - 65px);

		@media only screen and (max-width: 880px) {
			text-align: center;
			padding-left: 0;
			padding-bottom: 24px;
			height: 96px;
		}
	}
}