nav {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;

	display: flex;
	align-items: center;
	background: #30334b;
	height: 65px;
	display: flex;
	justify-content: center;
	z-index: 2;

}


.navlink {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 15px;
	height: 10%;
	cursor: pointer;


	font-family: Helvetica;
	font-size: 20px;
	color: white;


	border: 5px solid transparent;
	border-radius: 5px;
	background-color: transparent;
	transition: background-color 150ms linear;

	&:hover {
		background-color: rgba(85, 90, 192, 0.5);
	}
}
